$(document).ready(function () {
    var form = $("#demo-request");
    var submitButton = form.find("button[type='submit']");
    var originalButtonText = submitButton.find(".button-text").text();
    var successMessage = $(".form-alert.success");
    var errorMessage = $(".form-alert.error");
    var recaptchaSiteKey = "6LdA8BgmAAAAAECb8QcDjdQp3MNH8VvGoNfOLbzi";

    $.validator.addMethod("checkDemoSelection", function (value, element) {
        return $("input[name='demo[]']:checked").length > 0;
    }, "Selecteer ten minste één parameter.");

    form.validate({
        rules: {
            name: {
                required: true,
                minlength: 2,
                maxlength: 50
            },
            phone: {
                required: true,
                minlength: 8,
                maxlength: 15
            },
            email: {
                required: true,
                email: true,
                maxlength: 100
            },
            company: {
                required: true,
                minlength: 2,
                maxlength: 100
            },
            terms: {
                required: true
            },
            message: {
                required: true,
                minlength: 10,
                maxlength: 300
            },
            "demo[]": {
                checkDemoSelection: true
            }
        },
        messages: {
            name: {
                required: "Voer uw naam in.",
                minlength: "Minstens {0} karakters invoeren.",
                maxlength: "Maximaal {0} karakters invoeren."
            },
            phone: {
                required: "Voer uw telefoonnummer in.",
                minlength: "Minstens {0} cijfers invoeren.",
                maxlength: "Maximaal {0} cijfers invoeren."
            },
            email: {
                required: "Voer een geldig e-mailadres in.",
                email: "Voer een geldig e-mailadres in.",
                maxlength: "Maximaal {0} karakters invoeren."
            },
            company: {
                required: "Voer een geldig bedrijf of organisatie in.",
                minlength: "Minstens {0} karakters invoeren.",
                maxlength: "Maximaal {0} karakters invoeren."
            },
            terms: "Accepteer de algemene voorwaarden.",
            message: {
                required: "Voer uw vraag of opmerking in.",
                minlength: "Minstens {0} karakters invoeren.",
                maxlength: "Maximaal {0} karakters invoeren."
            },
            "demo[]": {
                checkDemoSelection: "Selecteer ten minste één parameter."
            }
        },
        errorElement: "div",
        errorPlacement: function (error, element) {
            if (element.attr("type") === "checkbox") {
                error.addClass("invalid-feedback");
                var lastCheckbox = element.closest(".form-row").find("input[type='checkbox']").last();
                error.insertAfter(lastCheckbox.closest(".form-check"));
            } else {
                error.addClass("invalid-feedback");
                error.insertAfter(element);
            }
        },
        highlight: function (element, errorClass, validClass) {
            $(element).closest(".form-group").addClass("has-error");
        },

        unhighlight: function (element, errorClass, validClass) {
            $(element).closest(".form-group").removeClass("has-error");
        },
        submitHandler: function (form) {
            submitButton.prop("disabled", true);
            submitButton.find(".button-text").text("Verzenden...");
            submitButton.css("cursor", "progress");

            grecaptcha.ready(function () {
                grecaptcha.execute(recaptchaSiteKey, {action: 'submit'})
                    .then(function (token) {
                        $.ajax({
                            url: "/save-demo-request-form",
                            type: "POST",
                            data: $(form).serialize() + "&recaptcha_token=" + token,
                            success: function (response) {
                                successMessage.show();
                                errorMessage.hide();
                                $(form)[0].reset();
                                $(".title.gray-50.text-style-5").addClass("text-success");
                                submitButton.find(".button-text").text(originalButtonText);
                                submitButton.css("cursor", "auto");
                            },
                            error: function (xhr, status, error) {
                                successMessage.hide();
                                errorMessage.show();
                                submitButton.find(".button-text").text(originalButtonText);
                                submitButton.css("cursor", "auto");
                            },
                            complete: function () {
                                submitButton.prop("disabled", false);
                            }
                        });
                    });
            });
        }
    });

    submitButton.on("click", function () {
        successMessage.hide();
    });
});




